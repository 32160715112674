<template>
  <div>
      Accounting 
      <!-- <h3>Under Active Development</h3> -->
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
  components: {
   
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({

  }),
  methods: {

  }
};
</script>